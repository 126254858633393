import React, { Children, useEffect, useRef, useState } from 'react'
import config from '../config/config';
import { HubConnectionBuilder, HubConnectionState, HttpTransportType } from "@microsoft/signalr";
import moment from 'moment';
import { Socket } from 'socket.io-client';
import axios from 'axios';


export const AuthProvider = () => {
      
  // SOCKET SIGNAL R
  const [socket, setSocket] = useState(null);  // SOCKET SIGNAL
  const [UnPluggedInfo, setUnPluggedInfo] = useState([])
  // const intervalRef = useRef(null);
  const [ConexionSocket, setConexionSocket] = useState(localStorage.getItem("conexionSignalR"))
  const [RecolectoresMapa, setRecolectoresMapa] = useState([])
  const [FechayHora, setFechayHora] = useState(moment().format('YYYY-MM-DD, h:mm:ss a'))
  const [Tokenwsp, setTokenwsp] = useState('EAAaSIa6o1uwBO7FZBkEBoPjXN63eKBg8IjzG58DiV1gE1yZA6ktwNDk8tu5XEPZBKAZBxuakMebmvf2fWC1RlcKiYbWDf57xp5i6XxIlHZBgTvVV12oQIgSaE5raHd5BSpJhTT65oLrZBpZBeUKGn5HBApVsr8V6ZCHJ15B32FJXs1OwRIkxbY6lKz0UNoyyj1D8DevRD9y4n19VACrrqSsZD')
  
  //Token Permanente Admin: EAAaSIa6o1uwBOzGTF5jW3B5lY1qTrYq51lfkh5Y7MmJnJldSLGoo09OczaTxut3lmezzwQYu2dULjt0l9JJWPldBDFJmWm3SbwJwvVhUPyktf29mKwMHH23ONi3dX3mElHg7RZC7RvNKecIkLqI0bGxouYx74NBCsBryRLXiXcsvo8ZCF2P4wBVEodQ13eRQSDlQRj4oCldYzNwLd08Kd29U3vrWqqYui75wYZD
  
  // FUNCIONES SOCKET
  const StartSocket = async () => {
    console.log('Starting Socket...')
    const connect = new HubConnectionBuilder()
    .withUrl(`${config.Socket_URL}?userId=${740}&origin=monitor`, {
      withCredentials: false,
      transport: HttpTransportType.LongPolling,
    })
    .withAutomaticReconnect()
    .build();
    connect.serverTimeoutInMilliseconds = 10000;
    setSocket(connect);
    
    try {
      await connect.start()
      .then(res =>{console.log('crupper', res)})
      console.log('connect', connect)
      setConexionSocket(true)
      localStorage.setItem('conexionSignalR', true)
    } catch (error) {
      console.error(error, "ERROR SOCKET")
      
    }


    connect.onclose(async () => {

      setConexionSocket(false)
      localStorage.setItem('conexionSignalR', false)
      // IntervalConexion()
    })
  }

  const StopSocket = async () => {
    console.log('Stopping socket...', socket)
    if (socket && socket.state !== 'Disconnected') {
      await socket.stop();
    }
  }
  const ReconectSocket = async () => {
    console.log('Reconnecting Socket...', socket)
    if (!socket) return;
    if (localStorage.getItem("conexionSignalR") === "false") {
      StartSocket()
    }
  }

  const IntervalConexion = async () => {
    console.log('IntervalSocket...?', socket)

    // console.log('Interval: ', intervalRef)
    setInterval(() => {
      if (localStorage.getItem("conexionSignalR") === "false") {
        ReconectSocket()
      }
    }, 5000);
  }

  const handleUpdateMarkers = async(NewMarcadores) => {

    if (NewMarcadores != RecolectoresMapa) {
      setRecolectoresMapa(NewMarcadores)
      console.log('Marcadores actualizados: ', NewMarcadores)
    }else{
      console.log('Los marcadores no han cambiado...')
    }


  }


// INICIAR SOCKET
    // useEffect(() => {
    //     // if (user?.id) {
    //     StartSocket()
    //     // }
    // }, [])

  useEffect(() => {
    if (!socket) return;
    socket.on("ActivePickers", (marcadores) => {
        console.log(FechayHora, ' Actualizando marcadores... ')
      handleUpdateMarkers(marcadores)

    });

  }, [socket])


  useEffect(() => {
    if (socket?.state == 'Disconnected' || socket?.state == null ) return;
    let CrashInfo = {
      "Tiempo": FechayHora,
      "Socket": socket
    }
    console.log(CrashInfo)
    console.log(FechayHora)
    let Crashed = [...UnPluggedInfo, CrashInfo]
      setUnPluggedInfo(Crashed)
      IntervalConexion()
  }, [ConexionSocket])
  

  
  const SocketStatus = () =>{
    if (socket) {
      console.log('Socket', socket)
    } else {
      console.log('No hay datos del socket', socket)    
    }
    
    }

    const socketData = () => {
      console.log('Datos Socket: ', RecolectoresMapa)
    }
    

    const sendMessage = async () => {
      

      console.log('Enviando mensaje [ Socket Status: ', socket.state, ' ]' )

      let WspInfo = JSON.stringify({
        "messaging_product": "whatsapp",
        "to": "+52 668 183 0865",
        "type": "template",
        "template": {
            "name": "socket_warning",
            "language": {
                "code": "Es_MX"
            }
        }
      })

      let ConfigPetition = {
        url : 'https://graph.facebook.com/v19.0/329008650287542/messages',
        method : 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + Tokenwsp
        },
        data: WspInfo
      }

      try {
        await axios(ConfigPetition)
        
      } catch (error) {
        console.error(error)
      }
    };
    

  return (

    <div className='SocketBody'>

      <button>
        <p onClick={()=> StartSocket()}>Iniciar</p>
      </button>

      <button>
        <p onClick={()=> ReconectSocket()}>Reconectar</p>
      </button>

      <button>
        <p onClick={()=> StopSocket()}>Desconectar</p>
      </button>


      <div>
        <p>{`Recolectores conectados: ${RecolectoresMapa?.length}`}</p>
        <p>{`Estatus conexión: ${socket?.state ? socket?.state : 'Disconnected'}`}</p>
      </div>
      
      
      <button>
        <p onClick={()=> SocketStatus()}>Estatus Socket</p>
      </button>

      <button>
        <p onClick={()=> socketData()}>Datos Socket</p>
      </button>

      <button>
        <p onClick={()=> sendMessage()}>Mensaje Whatsapp</p>
      </button>
    </div>

    // <AuthProvider value={{
    //   StartSocket,
    //   StopSocket,
    //   ReconectSocket,
    //   IntervalConexion,
    //   socket,
    //   ConexionSocket,
    //   RecolectoresMapa,
    //   FechayHora
    // }}>{Children}</AuthProvider>
  )
}
